import React, { useEffect, useState } from "react";
import "./App.css";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import porse from "./data/Porse.json";
import slaan from "./data/Slaaen.json";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

type coords = { latitude: number; longitude: number };
type Herb = "slaan" | "porse";
function App() {
  const [currentPosition, setCurrentPosition] = useState<coords>({
    latitude: 55.676098,
    longitude: 9.568337,
  });
  const [selectedHerb, setSelectedHerb] = useState<any>(porse);
  const [selectedHerbName, setSelectedHerbName] = useState<string>("Porse");

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }, []);

  // let selectedHerb = porse;
  const aYearAgo = new Date();
  aYearAgo.setFullYear(aYearAgo.getFullYear() + -2);
  function filter() {
    return selectedHerb.filter((x: any) => aYearAgo < new Date(x.EventDate));
  }

  function success(position: { coords: coords }) {
    navigator.geolocation.getCurrentPosition(
      () => {},
      () => {}
    );
    setCurrentPosition(position.coords);
  }
  function error() {
    console.log("Unable to retrieve your location");
  }
  console.log(currentPosition);
  function handleChangeHerb(herb: Herb) {
    if (herb === "porse") {
      setSelectedHerb(porse);
      setSelectedHerbName("Porse");
    } else if (herb === "slaan") {
      setSelectedHerb(slaan);
      setSelectedHerbName("Slåen");
    }
  }

  function mapsSelector(latitude: string, long: string) {
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.platform.indexOf("iPhone") !== -1 ||
      navigator.platform.indexOf("iPad") !== -1 ||
      navigator.platform.indexOf("iPod") !== -1
    ) {
      return (
        "maps://maps.google.com/maps?daddr=" +
        latitude +
        "," +
        long +
        "&amp;ll="
      );
    } else {
      return (
        "https://maps.google.com/maps?daddr=" +
        latitude +
        "," +
        long +
        "&amp;ll="
      );
    }
  }
  return (
    <>
      <MapContainer
        center={[55.676098, 9.568337]}
        zoom={20}
        style={{ height: "100vh" }}
      >
        <ChangeView
          center={[currentPosition?.latitude, currentPosition?.longitude]}
          zoom={16}
        />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {filter().map((x: any) => (
          <Marker position={[x.DecimalLatitude, x.DecimalLongitude]}>
            <Popup maxWidth={100}>
              <div>Precision: {x.CoordinateUncertaintyInMeters | 0}m</div>
              <a href={mapsSelector(x.DecimalLatitude, x.DecimalLongitude)}>
                Dato:
                {new Date(x.EventDate)
                  .toLocaleDateString("da-DK")
                  .replaceAll(".", "-")}
              </a>
              <div>{selectedHerbName}</div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      <Fab
        icon={<FontAwesomeIcon icon={faPlus} />}
        alwaysShowTitle={true}
        mainButtonStyles={{ backgroundColor: "#3498db" }}
        style={{ top: 0, right: 0, color: "#FFF" }}
      >
        <Action
          text="Slåen"
          onClick={() => handleChangeHerb("slaan")}
          style={{ backgroundColor: "#3498db" }}
        >
          S
        </Action>
        <Action
          text="Porse"
          onClick={() => handleChangeHerb("porse")}
          style={{ backgroundColor: "#3498db" }}
        >
          P
        </Action>
      </Fab>
    </>
  );
}
type TChangeView = {
  center: any;
  zoom: number;
};
function ChangeView({ center, zoom }: TChangeView) {
  const map = useMap();
  map.setView(center, zoom);
  return null;
}
export default App;
